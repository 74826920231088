@import "custom-lms-layout";

$focus-stroke-width: 0.25rem;

.btn {
  border-radius: 4px;
  font-weight: $fw-semibold;
  &.focus,
  &:focus {
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    box-shadow: none;
    cursor: not-allowed;
    pointer-events: all !important;
  }

  &.no-radius {
    border-radius: 0;
  }
}

a.btn{
  color:white;
}

a.btn:hover {
  text-decoration: none;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-rounded {
  border-radius: 2rem !important;
}

a.btn-primary,
.btn-primary {
  color: $white !important;
  background-color: $primary;
  border-color: $primary;
  &.disabled,
  &:disabled {
    color: $white !important;
    cursor: if($enable-pointer-cursor-for-buttons, not-allowed, null);
    background-color: $pink-300;
    border-color: $pink-300;
    &:hover,
    &.hover {
      background-color: $pink-300;
      border-color: $pink-300;
    }
    &:focus,
    &.focus {
      background-color: $pink-300;
      border-color: $pink-300;
    }
  }

  &:not(:disabled):not(.disabled) {
    &:hover,
    &.hover {
      color: $white !important;
      background: $pink-700;
      border-color: $pink-700;
    }
    &:active,
    &.active {
      color: $white !important;
      background: $pink-700;
      box-shadow: 0 0 0 0.2rem $pink-300;
    }
    &:focus,
    &.focus {
      color: $white !important;
      background: $pink-700;
      border-color: $pink-700;
    }
  }
}

a.btn-outline-primary,
.btn-outline-primary {
  color: $pink !important;
  border-color: $pink;
  &.disabled,
  &:disabled {
    border-color: $pink-300;
    cursor: if($enable-pointer-cursor-for-buttons, not-allowed, null);
    color: $pink-300 !important;
  }

  &:not(:disabled):not(.disabled) {
    &:hover,
    &.hover {
      background: $pink-100;
      color: $pink !important;
      border-color: $pink;
    }
    &:active,
    &.active {
      color: $pink !important;
      background: $pink-100;
      border: 1px solid transparent;
      box-shadow: 0 0 0 0.2rem $pink-700;
    }
  }
}

a.btn-secondary,
.btn-secondary,
.btn-secondary:focus {
  color: $white !important;
  background-color: $secondary;
  border-color: $secondary;

  &.disabled,
  &:disabled {
    color: $white !important;
    cursor: if($enable-pointer-cursor-for-buttons, not-allowed, null);
    background-color: $m-blueberry-lightest;
    border-color: $m-blueberry-lightest;
    &:hover,
    &.hover {
      background-color: $m-blueberry-lightest;
      border-color: $m-blueberry-lightest;
    }
  }

  &:not(:disabled):not(.disabled) {
    &:hover,
    &.hover {
      color: $white !important;
      background: $m-blueberry-dark;
      border-color: $m-blueberry-dark;
    }
    &:active,
    &.active {
      color: $white !important;
      background: $m-blueberry-dark;
      box-shadow: 0 0 0 0.2rem $m-blueberry-lightest;
    }
  }
}

a.btn-outline-secondary,
.btn-outline-secondary {
  color: $secondary !important;
  border-color: $secondary;
  &.disabled,
  &:disabled {
    cursor: if($enable-pointer-cursor-for-buttons, not-allowed, null);
    color: $m-blueberry-light;
    border-color: $m-blueberry-light;
  }

  &:not(:disabled):not(.disabled) {
    &:hover,
    &.hover {
      background: $m-blueberry-lightest;
      color: $m-blueberry-darkest;
    }
    &:active,
    &.active {
      color: $m-blueberry-darkest;
      background: $m-blueberry-lightest;
      border: 1px solid transparent;
      box-shadow: 0 0 0 0.2rem $m-blueberry-darkest;
    }
  }
}

.btn-outline,
.btn-icon,
.btn-outline-neutral-300,
.btn-outline-light {
  color: $neutral-900 !important;
  background: white !important;
  border-color: $neutral-400 !important;

  &.disabled,
  &:disabled {
    cursor: if($enable-pointer-cursor-for-buttons, not-allowed, null);
    color: $neutral-400;
    background: $neutral-100 !important;
    &:hover,
    &.hover {
      border-color: $neutral-400;
    }
  }

  &:not(:disabled):not(.disabled) {
    &:hover,
    &.hover {
      background: $neutral-200 !important;
      border-color: $neutral-400 !important;
    }
    &:active,
    &.active {
      background: $neutral-200 !important;
      box-shadow: 0 0 0 0.2rem $neutral-800 !important;
    }
  }
}

.btn-square {
  width: 35px !important;
  max-width: 100% !important;
  max-height: 100% !important;
  height: 35px !important;
  text-align: center;
  padding: 0px;
}

.btn-light {
  @include button-variant($light, $light, $neutral-900, $neutral-500, $neutral-500, $neutral-700, $neutral-700);
}

.btn-sm.btn-square {
  width: 31px !important;
  max-width: 100% !important;
  max-height: 100% !important;
  height: 31px !important;
  text-align: center;
  padding: 0px;
}

.btn-lg.btn-square {
  width: 47px !important;
  max-width: 100% !important;
  max-height: 100% !important;
  height: 47px !important;
  text-align: center;
  padding: 0px;
}

.btn-danger,
.btn-dange:focus {
  color: $white;
  background: $red-600;
  border-color: $red-600;

  &.disabled,
  &:disabled {
    color: $white;
    cursor: if($enable-pointer-cursor-for-buttons, not-allowed, null);
    background-color: $red-300;
    border-color: $red-300;
    &:hover,
    &.hover {
      background-color: $red-300;
      border-color: $red-300;
    }
  }

  &:not(:disabled):not(.disabled) {
    &:hover,
    &.hover {
      color: $white;
      background: $red-700;
      border-color: $red-700;
    }
    &:active,
    &.active {
      color: $white;
      background: $red-700;
      box-shadow: 0 0 0 0.2rem $red-300;
    }
  }
}

.btn-success,
.btn-success:focus {
  color: $white !important;
  background-color: $green-600;
  border-color: $green-600;
  &.disabled,
  &:disabled {
    color: $white !important;
    cursor: if($enable-pointer-cursor-for-buttons, not-allowed, null);
    background-color: $green-300;
    border-color: $green-300;
    &:hover,
    &.hover {
      background-color: $green-300;
      border-color: $green-300;
    }
  }

  &:not(:disabled):not(.disabled) {
    &:hover,
    &.hover {
      color: $white !important;
      background: $green-700;
      border-color: $green-700;
    }
    &:active,
    &.active {
      color: $white !important;
      background: $green-700;
      box-shadow: 0 0 0 0.2rem $green-300;
    }
  }
}

.btn-pill {
  border-radius: 32px !important;
}

/* Primary buttons colours */
.btn-white {
  @extend .btn-outline-light;
  border: 1px solid transparent !important;
  /* background: white !important; */
  &:hover,
  &.hover {
    border: 1px solid transparent !important;
  }

  &:active,
  &.active {
    border: 1px solid $neutral-400 !important;
  }
}

a.btn-default,
.btn-default {
  &.disabled,
  &:disabled {
    border: 0;
  }
}

.dropdown-item.btn-white {
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active,
    &:focus,
    &.focus {
      box-shadow: none !important;
    }
  }
}

.btn-link {
  padding: 0.25rem 0rem !important;
  color: $neutral-900 !important;
  font-weight: $link-font-weight !important;
  &.hover,
  &:hover {
    text-decoration: underline;
    color: $neutral-900 !important;
  }

  &.disabled,
  &:disabled {
    color: $neutral-600 !important;
    cursor: not-allowed;
    pointer-events: all !important;

    &:hover,
    &.hover {
      color: $neutral-600 !important;
    }
    &:active,
    &.active {
      color: $neutral-600 !important;
    }
  }
}

.btn-warning:focus,
.btn-warning:active {
  color: inherit !important;
}

.btn-group .btn-outline-secondary {
  @include button-outline-variant($secondary, $secondary, $secondary, $secondary, $white);
}

.btn-outline-danger {
  // @extend .btn-outline-red-700;
  &.hover,
  &:hover {
    color: #fff !important;
  }
}

.dropdown-toggle::after {
  display: none;
}
