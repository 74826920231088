@import "custom-lms-layout";
//

// Toaster Positions and Styles

//
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 24px;
  left: 24px;
}

.toast-top-right {
  top: 24px;
  right: 24px;
}

.toast-top-right-below-header {
  top: $lms-header-height + 24px;
  right: 24px;
}

.toast-bottom-right {
  right: 24px;
  bottom: 24px;
}

.toast-bottom-left {
  bottom: 24px;
  left: 24px;
}

/* toast styles */
.toast-title {
  font-weight: $fw-semibold;
  color: white;
  text-transform: capitalize;
  font-size: $paragraph;
  padding-bottom: 4px;
}

.toast-message {
  word-wrap: break-word;
  color: white;
  font-size: $small;
  font-weight: $fw-medium;
}

.toast-message a,
.toast-message label {
  color: white;
}

.toast-message a:hover {
  color: $neutral-200;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: $fw-semibold;
  color: #ffffff;
}

.toast-close-button:hover,
.toast-close-button:focus {
  // color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 16px;
  width: 350px;
  border-radius: 4px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  // box-shadow: 0px 0px 2px rgba($dark, 0.25);
  color: #ffffff;
  box-shadow: 0 4px 20px 0 hsla(0, 0%, 0%, 0.15);
  line-height: 100%;
  &.toast-width-400 {
    width: 400px !important;
  }
}

.toast-container .ngx-toastr:hover {
  opacity: 1;
  cursor: pointer;
}

.toast-info {
  color: $blue-700 !important;
  background-color: $blue-100 !important;
  border: 1px solid $blue-700 !important;

  .toast-title {
    color: $blue-700 !important;
  }

  .toast-message {
    color: $blue-700 !important;
    // background-image: url("../assets/images/toast-icons/info.svg");
  }
}

.toast-error {
  color: $red-800 !important;
  background-color: $red-100 !important;
  border: 1px solid $red-800 !important;

  .toast-title {
    color: $red-800 !important;
  }

  .toast-message {
    color: $red-800 !important;
    // background-image: url("../assets/images/toast-icons/warning.svg");
  }

  .toast-close-button span {
    color: $red-800 !important;
  }
}

.toast-success {
  color: $green-800 !important;
  background-color: $green-100 !important;
  border: 1px solid $green-800 !important;

  .toast-title {
    color: $green-800 !important;
  }

  .toast-message {
    color: $green-800 !important;
  }

  .toast-close-button span {
    color: $green-800 !important;
  }

  // background-image: url("../assets/images/toast-icons/success.svg");
}

.toast-warning {
  color: $yellow-700 !important;
  background-color: $yellow-100 !important;
  border: 1px solid $yellow-700 !important;

  .toast-title {
    color: $yellow-700 !important;
  }

  .toast-message {
    color: $yellow-700 !important;
  }

  // background-image: url("../assets/images/toast-icons/warning.svg");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: $dark;
  color: $white;
  pointer-events: auto;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  // background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}

@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}

@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
