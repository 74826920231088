@import "./custom-lms-layout";
@import "bootstrap/scss/mixins";
/* Wrapper
 Padding will crash the checkboxes' style
*/
ng2-smart-table table tr th.ng2-smart-th {
  padding: 0.75rem 1rem !important ;
}

.min-width-100 ng2-smart-table table td {
  min-width: 100px;
}

.table-overflow {
  overflow: auto;
  width: 100%;
}
.table-wrap {
  background: white;
  border: $border-light;
  border-radius: $border-radius;
  overflow-x: auto;
  z-index: 10;
}

/* Tab header */
.custom-border-header {
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  border-left: $border-light;
  border-right: $border-light;
  border-top: $border-light;
}

/* Before pager */
ng2-smart-table table {
  border-bottom: $border-light;
}

/* Title */

// table-cell-view-mode,
// .ng2-smart-title {
//   @extend .text-nowrap;
// }



/* Sort */
.ng2-smart-sort-link.sort {
  // color: $neutral-900;
  font-weight: $fw-medium !important;

  &::after {
    right: 0.75rem;
    // color: $neutral-900;
    border-width: 0.375rem;
    position: absolute;
    margin: 0;
    content: '↕';
  }
}
a.sort.asc::after,
a.sort.desc::after {
  border: 6px solid transparent;
  // border-bottom-color: $neutral-900 !important;
  border-radius: 1px;
}

.ng2-smart-sort {
  font-weight: $fw-medium !important;
}

/* Pagination */
.ng2-smart-pagination,
.page-link-next,
.page-link-prev {
  font-size: unset !important;
}
// ng2-smart-table-pager {
//   margin: 0.75rem;
// }

.ng2-smart-pagination.pagination .page-link {
  &:focus {
    box-shadow: none !important;
  }
}

.page-item.disabled .page-link {
  background: $neutral-300 !important;

  & span {
    color: $neutral-600 !important;
  }
}

// hide bottom shadow of bottom row
.ng2-smart-row:last-child > .table-sticky-column-left {
  clip-path: inset(-1px -15px 0px 0px);
}

ng2-smart-table table {
  border-collapse: separate !important;
}

.ng2-smart-action-multiple-select {
  text-align: center;
}

// table checkbox
.ng2-smart-action-multiple-select > input {
  width: 20px;
  height: 20px;
  display: display-outside;
}

// table header checkbox
ng2-smart-table th > input {
  @extend .form-control;
  width: 20px;
  height: 20px;
  display: display-outside;
}

/* Fixed column widths starts*/
.ng2-smart-title {
  width: 134px !important;
  // overflow: hidden;
  // text-overflow: ellipsis;
}

// ngb popover
.popover {
  z-index: 1000 !important;
}

.popover-header {
  padding: 12px;
}

.popover-body {
  padding: 18px 12px;
}

.popover-body div:not(:last-child) {
  margin-bottom: 12px;
}

/*Table tooltip*/
.table-custom-tooltip .tooltip-inner {
  background: $dark;
  color: $white;
  font-size: $small;
  text-align: left !important;
  line-height: 1.25rem;
  max-width: 500px;
  padding: 0.75rem;
  border-radius: $border-radius;

  @include media-breakpoint-up(lg) {
    min-width: 300px;
  }
}

.table-custom-tooltip .arrow::before {
  content: none !important;
}

ng2-smart-table-title a {
  color:inherit !important;
}
