@use "@angular/material" as mat;
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap");

// themes - our custom or/and out of the box themes
@import "themes";

// framework component themes (styles tied to theme variables)
@import "@nebular/theme/styles/globals";
@import "@nebular/auth/styles/globals";

// import Nebular customized bootstrap, otherwise the form validation style is gone.
// @import '@nebular/bootstrap/styles/globals';

// Angular Material themes
@import "@angular/material/prebuilt-themes/indigo-pink.css";
// @import "bootstrap/scss/functions";
// @import "bootstrap/scss/variables";
// @import "bootstrap/scss/mixins";
// @import "bootstrap/scss/grid";
@import "bootstrap/scss/bootstrap";

// loading progress bar theme
@import "./pace.theme";

@import "./layout";
@import "./overrides";

// install the framework and custom global styles
@include nb-install() {
  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
  // @include nb-bootstrap-global();
}

.nb-theme-default h1,
.nb-theme-default h2,
.nb-theme-default h3,
.nb-theme-default h4,
.nb-theme-default h5,
.nb-theme-default h6 {
  //font-family: 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-family: Exo, sans-serif;
  color: #2a2a2a;
  font-weight: 500;
}

.nb-theme-default h1 {
  font-size: 2.5rem;
}

.nb-theme-default h2 {
  font-size: 2rem;
}

.nb-theme-default h3 {
  font-size: 1.75rem;
}

.nb-theme-default h4 {
  font-size: 1.5rem;
}

.nb-theme-default h5 {
  font-size: 1.25rem;
}

.nb-theme-default h6 {
  font-size: 1rem;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.form-control-danger {
  border: 1px solid red;
}

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$theme-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$theme-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$theme-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn
    ),
    typography: mat.m2-define-typography-config()
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($theme);

// @import "@ausmed/ausmed-styles/scss/variables";
// @import "@ausmed/ausmed-styles/scss/type";
// @import "@ausmed/ausmed-styles/scss/utilities";
// @import "@ausmed/ausmed-styles/scss/layouts";
@import "./custom-styles-b2b/custom-styles";

@import "./custom-styles-b2b/custom-toast";
// @import "./custom-styles-b2b/custom-ng-select";
@import "./custom-styles-b2b/b2b-buttons";
@import "./custom-styles-b2b/b2b-inputs";
// @import "./custom-styles-b2b/b2b-badges";
// @import "./custom-styles-b2b/b2b-cards";
// @import "./custom-styles-b2b/b2b-nav-links";
// @import "./custom-styles-b2b/b2b-modal";
// @import "./custom-styles-b2b/b2b-alert.scss";

nb-card{
  border-radius: 0.375rem !important;
}
